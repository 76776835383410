body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
table.assessment-table {
  border-collapse: collapse;
  font-size: 75%;
  position: relative;
}
.assessment-table th {
  position: sticky;
  top: 0;
  background: white;
}
.assessment-table th,
.assessment-table td {
  border: 1px solid black;
  padding: 0.15rem;
}
.assessment-table tr td {
  height: 1.25em;
}
.assessment-table td.selected {
  border: 2px solid black;
}
.assessment-table textarea {
  font-family: inherit;
  font-size: 100%;
}
.MuiCard-root {
  overflow: inherit !important;
}
.risk-matrix .axis {
  float: left;
  margin-top: 0px;
  margin-right: -152px;
  margin-bottom: 10px;
}
.risk-matrix .y-axis {
  float: left;
  padding-bottom: 5px;
  height: 165px;
}
/*
.risk-matrix .origo {
  width: 10px;
  height: 10px;
  margin-left: -11px;
}
*/
.risk-matrix .x-axis {
  margin-left: -6px;
  padding-top: 160px;
  width: 160px;
}
.risk-matrix td {
  padding: 0;
  width: 50px;
  height: 50px;
  text-align: center;
  margin: auto auto;
  font-weight: bold;
}
.risk-matrix .red {
  background-color: #dd1010;
  color: white;
}
.risk-matrix .green {
  background-color: #10dd10;
}
.risk-matrix .yellow {
  background-color: #eeee00;
}
